<template>
  <v-card>
    <v-card-title>
      <span class="text-h6">{{ $t("labels.set_permission") }}</span>
      <v-spacer></v-spacer>
      <v-btn
        color="success"
        outlined
        @click="updatePermission"
        small
        class="mr-2"
      >
        {{ $t("labels.save") }}
      </v-btn>
      <v-btn color="error" outlined @click="closeDialog" small>
        {{ $t("labels.close") }}
      </v-btn>
    </v-card-title>
    <v-card-text style="max-height: 600px; overflow-y: auto">
      <v-row no-gutters>
        <v-col
          cols="12"
          md="4"
          v-for="item in permissions"
          :key="`p_${item.value}`"
        >
          <v-checkbox
            dense
            hide-details
            class="c-input-small"
            v-model="selectedPermissions"
            :value="item.value"
            :label="item.text"
            multiple
            @change="onPermissionChanged"
          ></v-checkbox>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { httpClient } from "@/libs/http";

export default {
  name: "SubAccountPermission",
  props: {
    employee: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    isLoading: false,
    permissions: [],
    selectedPermissions: [],
    isChanged: false,
  }),
  computed: {},
  methods: {
    closeDialog() {
      this.$emit("closeDialog");
    },
    refreshData() {
      this.$emit("refreshData");
    },
    onPermissionChanged() {
      this.isChanged = true;
    },
    async getPermissions() {
      const { data } = await httpClient.post("/sub-account-permission", {
        id_identity: this.employee.id_identity,
      });
      this.isChanged = false;
      this.selectedPermissions = [...data.active_permissions].map((p) => p.id);
      this.permissions = [...data.permissions].map((e) => ({
        value: e.id,
        text: this.$i18n.locale === "vi" ? e.description : e.description_en,
      }));
    },
    async updatePermission() {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;
      try {
        await httpClient.post("/set-sub-account-permission", {
          id_identity: this.employee.id_identity,
          selected_permissions: this.selectedPermissions,
        });
        this.isLoading = false;
        this.isChanged = false;
        this.$vToastify.success(this.$t("messages.update_success"));
        this.refreshData();
        this.closeDialog();
      } catch (e) {
        this.isLoading = false;
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
      }
    },
  },
  mounted() {
    this.getPermissions();
  },
};
</script>

<style scoped lang="scss"></style>
